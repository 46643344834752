import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'


// DO NOT USE THE IMAGES IN PRODUCTION

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Software & Agency Website - Xtenture',
    github: 'https://github.com/',
    demo: 'https://dribbble.com/shots/19698925-Software-Agency-Website-Xtenture'
  },
  {
    id: 2,
    image: IMG2,
    title: 'WooCommerce Website - Techplanet',
    github: 'https://github.com/',
    demo: 'https://dribbble.com/shots/19711784-WooCommerce-Website-Techplanet'
  },
  {
    id: 3,
    image: IMG3,
    title: 'WooCommerce Website - Baking Labs',
    github: 'https://github.com/',
    demo: 'https://dribbble.com/shots/19711965-WooCommerce-Website-Baking-Labs'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Laravel Ecommerce Website - PicklesLab',
    github: 'https://github.com/',
    demo: 'https://dribbble.com/shots/19715958-Laravel-Ecommerce-Website-PicklesLab'
  },
  // {
  //   id: 5,
  //   image: IMG5,
  //   title: '',
  //   github: '',
  //   demo: ''
  // },
  // {
  //   id: 6,
  //   image: IMG6,
  //   title: '',
  //   github: '',
  //   demo: ''
  // },
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Works</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">

       {
        data.map(({id, image, title, github, demo})=>{
          return (
            <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt="title" />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                
                <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
              </div>
            </article>
          )
        })
       }

      </div>
    </section>
  )
}

export default Portfolio