import React from 'react'
import './experience.css'
import {IoMdCheckmarkCircleOutline} from 'react-icons/io'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">

        <div className="experience__designer">
          <h3>Creative Design</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>Adobe Photoshop</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>Adobe Illustrator</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>Adobe Premiere Pro</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>Adobe After Effect</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>Autodesk 3Ds Max</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            
            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>Blender</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

          </div>

        </div>
        {/* END  OF DESIGN */}

        <div className="experience__development">
          
          <h3>Web Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>ReactJs</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>Laravel</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>PHP</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            
            <article className='experience__details'>
              <IoMdCheckmarkCircleOutline className='experience__details-icon' />
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

          </div>

        </div>

         {/* END  OF DEVELOPMENT */}

      </div>

    </section>
  )
}

export default Experience