import React from 'react'
import './services.css'
import {GiCheckMark} from 'react-icons/gi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        {/* <article className="service">
          <div className="service__head">
            <h3>UI/UX DESIGN</h3>
          </div>

          <ul className='service__list'>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
          </ul>
        </article> */}
        {/* END OF UI/UX */}

        <article className="service">
          <div className="service__head">
            <h3>CREATIVE DESIGN</h3>
          </div>

          <ul className='service__list'>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Logo & Identity</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Business & Advertising</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Digital Marketing</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Art & Illustration</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Packaging & Label</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
          
        <article className="service">
          <div className="service__head">
            <h3>WEB DEVELOPMENT</h3>
          </div>

          <ul className='service__list'>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Corporate Web Design</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Landing Pages</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Custom Web Development</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Ecommerce & Payment Gateways</p>
            </li>
            <li>
              <GiCheckMark className='service__list-icon' />
              <p>Inventory Management Systems</p>
            </li>
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT*/}

      </div>
    </section>
  )
}

export default Services