import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avatar: AVTR1,
    name: 'Iranda Tharaka',
    review: 'They have fantastic attention to detail and their designs are next level. It was definitely beyond our expectations. Highly recommended for graphics designs & stream overlays' 
  },
  {
    avatar: AVTR2,
    name: 'Malitha Balawardana',
    review: 'Best place to get your web site done. Highly Recommended' 
  },
  {
    avatar: AVTR3,
    name: 'Roshan Adrian Wijeyegunetileke',
    review: 'Highly Recommended. Great service' 
  },
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
      >

       {
        data.map(({avatar, name, review}, index) => {
          return (
            <SwiperSlide key={index} className="testimonials">
              <div className="client__avatar">
                <img src={avatar} />
              </div>
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>{review}</small>
            </SwiperSlide>
          )
        })
       }

      </Swiper>

    </section>
  )
}

export default Testimonials